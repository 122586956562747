import React from "react"
import { inject, observer } from "mobx-react";
import Whatsapp from "../Whatsapp";
import styles from "./style.module.css"
import classnames from "classnames"
import { observable } from "mobx";

// @inject(stores => ({
//     chat: stores.chat_store,
//     tracker: stores.tracker_controller
// }))
// class Chat extends React.Component {

//     componentDidMount() {
//         setTimeout(() => {
//             window.purechatApi = {
//                 l: [],
//                 t: [],
//                 on: function () { this.l.push(arguments); }
//             };
//             window.purechatApi.on("chatbox:ready", () => {
//                 this.props.chat.init(window.purechatApi, this.props)
//                 window.purechatApi.on('chat:start', (args) => {
//                     this.props.tracker.trackWithGA({
//                         eventCategory: "Chat",
//                         eventAction: "started",
//                         eventValue: 1
//                     })
//                 })
//             });
//             (function () {
//                 let done = false;
//                 const script = document.createElement('script');
//                 script.async = true;
//                 script.type = 'text/javascript';
//                 script.src = 'https://app.purechat.com/VisitorWidget/WidgetScript';
//                 document.getElementsByTagName('HEAD').item(0).appendChild(script);
//                 script.onreadystatechange = script.onload = function (e) {
//                     if (!done && (!this.readyState || this.readyState === 'loaded' || this.readyState === 'complete')) {
//                         new window.PCWidget({ c: '5ad4b3e7-c751-4063-90d7-21bcca207c81', f: true });
//                         done = true;
//                     }
//                 };
//             })();
//         }, 0)
//     }

//     componentWillUnmount() {
//         const frame = document.getElementById("purechat-frame")
//         const container = document.getElementById("purechat-container")
//         if (frame) {
//             frame.parentElement.removeChild(frame)
//         }
//         if (container) {
//             container.parentElement.removeChild(container)
//         }
//         this.props.chat.dispose()
//     }

//     render() {
//         return null;
//     }
// }

@inject(stores => ({
    chat: stores.chat_store,
    tracker: stores.tracker_controller
}))
@observer
class WhatsappChat extends React.Component {


    componentDidMount(){
        this.props.chat.init(this.props)
    }

    render() {
        return <Whatsapp
            className={classnames(styles.wrapper, {[styles.visible]: this.props.chat.visible})}
            onClick={() => (
                this.props.tracker.trackWithGA({
                    eventCategory: "Chat",
                    eventAction: "started",
                    eventValue: 1
                })
            )}
        />
    }
}



export default WhatsappChat