import { observable, action } from "mobx";

// class ChatStore {
//     @observable _api = null


//     @action.bound
//     init(api, options) {
//         this._api = api
//         if (options.hideOnStart) {
//             this.setVisible(false)
//         }
//     }

//     @action.bound
//     dispose() {
//         this._api = null
//     }

//     @action.bound
//     setQuestion(question) {
//         if (this.initialized) {
//             this._api.set("visitor.question", question)
//         }
//     }

//     @action.bound
//     setExpanded(flag) {
//         if (this.initialized) {
//             this._api.set("chatbox.expanded", flag)
//         }
//     }

//     @action.bound
//     setVisible(flag) {
//         if (this.initialized) {
//             this._api.set("chatbox.visible", flag)
//         }
//     }


//     @computed
//     get initialized() {
//         return !!this._api
//     }


// }
class WhatsappChatStore {
    @observable visible = true

    @action.bound
    init(options) {
        if (options.hideOnStart) {
            this.setVisible(false)
        }
    }



    @action.bound
    setQuestion(question) {
     
    }

    @action.bound
    setExpanded(flag) {
        if(flag){
            window.open("https://wa.me/message/EFYPDIUHNYN4G1", "_blank")
        }
    }

    @action.bound
    setVisible(flag) {
        this.visible = flag
    }



}

export default WhatsappChatStore