import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Chat from "../components/Chat"
import { Provider } from "mobx-react"
import ChatStore from "../stores/chat.store"
import Contacts from "../components/Contacts"

class ContactsPage extends React.Component {

    constructor(props) {
        super(props)
        this.stores = {
            chat: new ChatStore()
        }
    }

    render() {
        return (
            <Provider
                chat_store={this.stores.chat}
            >
                <Layout scroll={false}>
                    <SEO title="Home" />

                    <Chat hideOnStart />

                    <div className="top-offset content text-center">
                        <h1>Have any questions?</h1>
                        <Contacts />
                    </div>
                </Layout>
            </Provider>
        )
    }
}

export default ContactsPage
