import React from "react"
import { inject, observer } from "mobx-react"
import classnames from "classnames"
import styles from "./style.module.css"
import Telephone from "../Telephone"

const Contacts = inject(stores => ({
    chat: stores.chat_store,
    tracker: stores.tracker_controller,
    header: stores.header_store,
}))(
    observer(({ chat, tracker, header }) => (
        <div className={classnames(styles.wrapper, "d-flex flex-wrap justify-content-center")}>
            <Telephone className={classnames(styles.card, "paper-low", "btn-link hover-drop text-black")}
                onDesktopClick={() => {
                    header.setCallIntentVisible(true)
                    tracker.trackWithGA({
                        eventCategory: "Telephone",
                        eventAction: "click",
                        eventLabel: "header - desktop",
                    })
                }}
                onMobileClick={() => {
                    tracker.trackWithGA({
                        eventCategory: "Telephone",
                        eventAction: "click",
                        eventLabel: "header - mobile",
                    })
                }}
            >
                <i className="icon-call" />  <span className="d-block text-secondary">Call us</span>
            </Telephone>
            {/* <h2>Call us</h2> */}
            <button
                className={classnames(styles.card, "paper-low")}
                onClick={(e) => {
                    tracker.trackWithGA({
                        eventCategory: "Email",
                        eventValue: "send",
                        eventLabel: "contact page"
                    })
                    const body = `Hello,\nI have a question regarding moving.`
                    e.preventDefault()
                    window.location = `mailto:${process.env.EMAIL}?subject=Removal Enquiry&body=${encodeURIComponent(body)}`
                }} aria-label="email us">
                <i className="icon-email" /> <span className="d-block text-secondary">Email us</span>
            </button>
            <button
                className={classnames(styles.card, "paper-low")}
                aria-label="chat with us" onClick={() => chat.setExpanded(true)}>
                <i className="icon-chat" />  <span className="d-block text-secondary">Chat with us</span>
            </button>
        </div >
    ))
)

export default Contacts